input[type="text"] {
    font-size: 2rem;
    margin: 10px 10px 0 10px;
}
h1 {
    margin: 0;
    padding:0;
}
h2 {
    font-size: 1.25rem;
    color: #ccc;
    margin: 0;
}
h2::first-letter {
    text-transform: capitalize;
  }
h2 > span {
    color: #666!important;
}
ul.results {
    list-style-type: none;
    margin: 0;
    padding:0;
}
ul > li {
    padding:10px
}
.res {
    border: 1px solid #ccc;
    padding: 20px;
}