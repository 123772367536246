article.a-page {
    padding: 40px 0;
    max-width: 760px;
    margin: 0 auto;
    font-family: 'IBM Plex Sans', sans-serif;
}

/* Headings */
article.a-page h1,
article.a-page h3 {
    font-family: 'Heebo', sans-serif;
}
article.a-page h1 {
    font-size: 2rem;
    font-weight: 800;
    margin: 5% 0 5% 0;
}
article.a-page h3 {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 5% 0 5% 0;
}
/* Content */
article.a-page p {
    font-size: 1.2rem;
    line-height: 1.75rem;
}
article.a-page blockquote {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 20px 0 0 0;
    text-decoration: underline;
}
article.a-page img {
    margin-left: -50%;
    max-width: 200%;
    margin-top: 40px;
}
article.a-page img.small-center {
    max-width: 50%;
    margin: 20px auto 40px auto;
    display: block;
}
div.a-page-cover {
    height: 45vh;
    overflow: hidden;
    position: relative;
}
div.a-page-cover h1 {
    font-size: 3rem;
    color: #fee559;
    font-weight: 800;
    margin: 0 0 0 0;
    position: absolute;
    bottom: 0%;
    width: 100%;
    left: 0;
    padding: 5% 30%;
    box-sizing: border-box;
    text-align: center;
    background: rgba(0, 0, 0, 0.651);
}
img.a-cover {
    max-width: 100%;
}
.a-related-articles {
    border-top: 1px solid #ccc;
    background: #f9f9f9;
    column-count: 3;
    column-gap: 50px;
    padding: 50px 50px 10% 50px;
    box-sizing: border-box;
}
.a-related-articles > div > aside {
    height: 280px;
    overflow: hidden;
}
.a-related-articles > div > aside > img {
    max-width: 100%;
}
.a-related-articles > div > h3 {
    font-size: 1.65rem;
    margin: 20px 0 0 0;
}