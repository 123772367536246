.App {
  text-align: center;
}
.btn {
  clear: both;
  display: block;
  padding:15px;
  border-radius: 5px;
  background: #000;
  width: 180px;
  margin: 20px auto;
  color: #fff;
  font-weight: 800;
}
.small {
  font-size: 0.8rem;
  font-weight: 400;
  color: #999;
  display: block;
}
.headers {
  border: 1px solid #ccc;
  display: inline-block;
  text-align: left;
  margin: 0;
  padding: 0;
}
.headers > li {
  display: inline-block;
  border-right: 1px solid #ccc;
  padding: 10px;
  font-weight: 800;
  line-height: 1.5rem;
}
.value-row {
  list-style: none;
  border: 1px solid #ccc;
  text-align: left;
  max-width: 80%;
  margin: 40px auto;
  padding: 0;
}
.row-2 {
  width: 340px;
}
.row-3 {
  width: 510px;
}
.row-4 {
  width: 680px;
}
.row-5 {
  width: 850px;
}
.row-6 {
  width: 1020px;
}
.row-7 {
  width: 1190px;
}
.row-8 {
  width: 1360px;
}
.value-row > li > ul {
  padding:0;
}
.value-row > li > ul > li {
  display: inline-block;
  border-right: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  vertical-align: top;
  width: 170px;
  max-height: 80px;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5rem;
  border-bottom: 1px solid #ccc;
}
.value-row > li > ul > li.remove-row {
  width: 40px;
  font-size: 1.25rem;
  margin-left:-40px;
  z-index: 999;
}
.bold {
  font-weight: 800;
}
.more-rows {
  margin: 0;
padding: 15px;
display: inline-block;
font-size: 0.8rem;
font-weight: 700;
}