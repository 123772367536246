body {
    background:#f1f1f1;
    background: radial-gradient(circle, rgba(255,255,255,1) 40%, rgb(211, 210, 210) 100%); 
}
@keyframes zoom {
    0% {
         transform: scale(1);
    }
    50% {
        transform: scale(1.5);
        opacity:0;
   }
   75% {
       opacity: 0.5;
   }
    100% {
        opacity:0.8;
         transform: scale(1);
    }
 }
 @keyframes spinCube {
    0% { transform: translateZ(-100px) rotateX(  0deg) rotateY(  0deg); }
  100% { transform: translateZ(100px) rotateX(360deg) rotateY(360deg); }
}
.spin-it {
    animation: spinCube;
    animation-duration: 20s;
  animation-iteration-count: infinite;
}
.bg-image {
    animation-name: zoom;
  animation-duration: 20s;
  animation-iteration-count: infinite;
}
@keyframes moveup {
    0% {
        transform: translateX(-100%);
    }
    33.33% {
        transform: translateX(0px);
   }
   66.66% {
        transform: translateX(0px);
        opacity:1;
    }
    100% {
        opacity:0;
        transform: translateX(100%);
    }
 }
.quote {
    animation-name: moveup;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}
h1 {
    text-shadow: 0 5px 25px rgba(0,0,0,0.2);
    transform: rotateY(-45deg);
}
h1.t-1 {
    font-family: 'Permanent Marker', cursive;
}
h1.t-2 {
    font-family: 'Caveat', cursive;
}
h1.t-3 {
    font-family: 'Bowlby One SC', cursive;
}
h1.t-4 {
    font-family: 'Fredericka the Great', cursive;
}
h1.t-5 {
    font-family: 'Bungee Inline', cursive;
}