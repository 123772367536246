.splitter h2 {
    margin: 0;
    padding: 0;
}
.splitter > div {
    background: red;
    height: inherit;
    float: left;
    position: relative;
}
.splitter .block-controls {
    position: absolute;
    top: 40%;
    width: 400px;
    margin-left:50%;
    z-index: 2;
}
.splitter > div > div.block-controls > h2 {
    font-size: 3rem;
    text-align: center;
}
.splitter > div > div.block-controls > h3 {
    font-size: 2rem;
    text-align: center;
    margin-top: 40px;
}
.splitter > div > div.block-controls > h4 {
    font-size: 1.52rem;
    text-align: center;
    margin-top: 40px;
}
.splitter .sub-block {
    background: purple;
    color: #fff;
    padding: 40px;
    box-sizing: border-box;
}
.splitter .sub-block + div {
    background: teal;
}
.splitter .sub-block + div + div {
    background: orange;
}

.splitter > div + div {
    background: blue;
}
.splitter > div + div + div {
    background: green;
}
.splitter > div + div + div + div {
    background: yellow;
}
.block {
    overflow: hidden;
}
.block img {
    position: absolute;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    z-index: 0;
}
.splitter-controls {
    position: fixed;
    top: 10px;
    left: 30px;
    z-index: 9999;
}
.add-root-block {
    height: inherit;
    position: fixed;
    z-index: 9999;
    right: 0;
    top: 0;
    width: 40px;
    background: #000;
}
.add-root-block > h2 {
    color: #fff;
    font-size: 2rem;
    font-weight: 800;
    margin-top: 400px;
    transform: rotate(90deg);
}