body {
    /* background-image:url("./frontpage-bg.png")!important; */
    background-repeat: no-repeat!important;
    background-size: cover;
}
.a-frontpage {
    max-width: 1140px;
    margin: 40px auto 400px auto;
    transition: 175ms;
}
.a-frontpage.is-showing {
    max-width: 95%;
}
@media only screen and (min-width: 601px) and (max-width: 1240px) {
    .a-frontpage.is-showing {
        max-width: 95%;
    }
}
@media only screen and (min-width: 1600px) {
    .a-frontpage.is-showing {
        max-width: 80%;
    }
}
.a-frontpage > .left-side {
    background: #000;
    height: 500px;
    float: left;
    width: 50%;
    margin-bottom: 20px;
}
.a-frontpage > .right-side {
    box-sizing: border-box;
}
.a-frontpage > .right-side > aside {
    background: #5ebc6d;
    background-image: url("./banner-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 5px 25px rgba(0,0,0,0.1);

    padding: 0;
    overflow:auto;
}
.a-frontpage > .right-side > aside > img {
    float: left;
    width: 200px;
    transition: 175ms;
}
.a-frontpage > .right-side > aside.banner-showing > img {
    float: left;
    width: 120px;
}
.a-frontpage > .right-side > aside.banner-showing .frontpage-links {
    display: none;
}

.a-frontpage > .right-side > aside > div {
    padding: 40px;
    box-sizing: border-box;
    float: left;
    transition: 175ms;
}
.a-frontpage > .right-side > aside.banner-showing > div {
    padding: 16px;
    box-sizing: border-box;
    float: left;
}
.a-frontpage > .right-side > div {
    background: #fff;
    padding: 0;
    overflow: auto;
    box-shadow: 0 5px 25px rgba(0,0,0,0.1);

}
.a-frontpage > .right-side > div > div {
    padding: 25px;
}
.a-frontpage .frontpage-links {
    padding-top: 10px;
}
.a-frontpage .frontpage-links > a {
    border: 1px solid #000;
    padding: 8px 15px;
    border-radius: 5px;
    color: #000;
    transition: 175ms;
    cursor: pointer;
}
.a-frontpage .frontpage-links > a:hover {
    color: #fff;
    border: 1px solid #000;
    background: #000;
}
.a-frontpage article {
    background: #fff;
    position: relative;
    padding-bottom: 80px;
}
.a-frontpage .zoom-hover {
    transition: 175ms;
    cursor: pointer;
    transform: scale(1.04);
}
.a-frontpage .zoom-hover:hover {
    transform: scale(1.05);
}
.a-frontpage .cover-pic {
    max-width: 35%;
    float: left;
    margin-right: 25px;
    overflow: hidden;
}
.a-frontpage > .under-intro > h3 {
    color: #000;
    margin: 15px 0 30px 0;
    padding-bottom: 15px;
    border-bottom: 1px dotted #ccc;
}
.a-frontpage > .under-intro {
    width: 50%;
    float: left;
    padding: 10px 20px;
    box-sizing: border-box;
    vertical-align: top;
    /* background: #675ebcb7; */
    font-family: 'IBM Plex Mono', monospace!important;
    margin: 0;
    margin-bottom: 150px;
    margin-top: 40px;
}
.a-frontpage > .under-intro.notes {
    /* background: #5e96bcb7; */
}
.under-intro > .under {
    clear: both;
    padding: 0;
}
.under-intro > .under > div {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 25px;
}
.under-intro h4.tag {
    font-size: 0.8rem;
    background: #000;
    color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    margin-top:0;
    display: inline-block;
}
.under-intro > .under > div > div {
    background: #fff;
    margin: 0;
}
.under-intro > .under > div > div > section {
    height: 240px;
    overflow: hidden;
}
.under-intro > .under > div > div > section > img {
    max-width: 100%;
}
.under-intro > .under > div > div > div {
    padding: 20px;
}
.a-frontpage iframe {
    border:none;
    width: 100%;
    height: 80vh;
}
.a-popin-page {
    left:0;
    bottom: 100%;
    position: fixed;
    width: 100%;
    transition: 500ms;
    background: #fff;
    z-index: 99999;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.a-popin-page.pop-in {
    top: 0%;
    left:0;
    transition: 500ms;
}
.a-frontpage h2 {
    color: #000;
    font-size: 1.30rem;
    margin: 0;
    padding: 0!important;
    font-weight: 800;
    font-family: 'IBM Plex Mono', monospace!important;

}
.a-frontpage p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.05rem;
}
.a-frontpage h2.close {
    cursor: pointer;
    margin: 30px 40px;
    font-size: 3rem;
    color: #000;
    z-index: inherit;
    float: left;
}
.a-frontpage h2 {
    font-family: 'IBM Plex Mono', monospace!important;
    margin: 0;
    padding: 0;
}
article.a-page {
    padding: 40px 0;
    max-width: 760px;
    margin: 0 auto;
    font-family: 'IBM Plex Sans', sans-serif;
}

/* Headings */
article.a-page h1,
article.a-page h2,
article.a-page h3,
article.a-page h4 {
    font-family: 'IBM Plex Mono', monospace!important;
    transform:none;
}
article.a-page h1 {
    font-size: 2rem;
    margin: 5% 0 5% 0;
    padding: 0;
}
article.a-page h2 {
    margin-top: 40px;
}
article.a-page h3 {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 5% 0 5% 0;
}
article.a-page h4 {
    font-size: 1rem;
    font-weight: 800;
    margin: 40px 0 -20px 0;
}
/* Content */
article.a-page p {
    font-size: 1.2rem;
    line-height: 1.75rem;
}
article.a-page a.article-btn {
    border: 1px solid #ccc;
    padding: 8px 18px;
    border-radius: 4px;
    text-decoration: none;
    color: #000;
    transition: 175ms;
}
article.a-page a.article-btn:hover {
    background: #eee;
}
article.a-page blockquote {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 20px 0 0 0;
    text-decoration: underline;
}
article.a-page img {
    max-width: 100%;
    margin: 40px auto;
}
article.a-page img.small-center {
    max-width: 50%;
    margin: 20px auto 40px auto;
    display: block;
}
div.a-page-cover {
    height: 45vh;
    overflow: hidden;
    position: relative;
}
img.a-cover {
    max-width: 100%;
}
.a-frontpage .right-side > aside > div.mobile-back {
    display: none;
}
.a-frontpage .right-side > aside > div.mobile-back > a {
    margin-top: 18px;
    vertical-align: top;
    font-size: 0.8rem;
}
.a-frontpage .arrow.arrow-left {
    vertical-align: top;
    width: 10px;
    height: 10px;
    border-bottom: 3px solid #000;
    border-left: 3px solid #000;
    transform: rotate(45deg);
    float: left;
    margin: 10px;
}
@media only screen and (max-width: 600px) {
    .a-frontpage {
        margin: 0;
    }
    .a-frontpage h2.close {
        display: none;
    }
    .a-frontpage > .right-side > aside {
        text-align: center;
    }
    .a-frontpage > .right-side > aside > img {
        margin: 40px;
        margin-top: 60px;
        float: none;
    }
    .a-frontpage > .right-side > aside > div {
        padding: 0 30px 50px 30px;
    }
    .a-frontpage .cover-pic {
        max-width: 100%;
        margin-bottom: 30px;
    }
    .a-frontpage > .under-intro {
        width: 100%;
        margin-bottom: 0;
    }
    .a-frontpage > .under-intro.notes {
        margin-bottom: 150px;
    }
    .a-frontpage.is-showing {
        max-width: 100%;
    }
    .a-frontpage article {
        padding: 20px;
    }
    .under-intro > .under > div > div > section {
        height: 180px;
    }
    .a-frontpage.is-showing .right-side {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99999;
    }
    .a-frontpage.is-showing .right-side > aside > div {
        display: none;
    }
    .a-frontpage.is-showing > .right-side > aside.banner-showing > div.mobile-back {
        display: initial;
    }
    .a-frontpage.is-showing {
        padding-top: 60px;
    }
    .a-frontpage .zoom-hover {
        transform: none;
    }
    .a-frontpage .zoom-hover:hover {
        transform: none;
    }
    .a-frontpage > .right-side > aside.banner-showing > img {
        display: none;
    }
}