.a-timeline {
    width: 800px;
    margin: 0 auto;
    overflow: auto;
    padding-top: 60px;
}
.a-timeline > .the-line {
    height: 100vh;
    border-left: 1px dotted#ccc;
    position: fixed;
    left: 50%;
    top:0;
    margin-left: 0px;
}
.a-timeline > .timeline-item > div > h2 {
    color: #000;
    padding: 15px;
    padding-bottom: 15px;
}
.a-timeline > .timeline-item > div > p {
    font-size: 1rem;
    margin-top: -10px;
    padding: 0 15px 5px 15px;
}
.a-timeline > .timeline-item {
    padding: 0;
    width: 47%;
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
}
.a-timeline > .timeline-item:nth-child(odd) {
    float: right;
    margin-top: 40px;
    margin-bottom: 0;
}
.a-timeline .timeline-date {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background:#fff;
    padding: 10px;
}
.a-timeline .timeline-date > span.the-date {
    font-weight: 400;
    float: right;
}
.a-timeline .timeline-date > span.countdown {
    font-size: 0.8rem;
}
.a-timeline > .timeline-item > div {
    border: 1px solid rgb(228, 191, 150);
    background: #f3e3cc;
    border-radius: 5px;
}
